//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --bs-font-sans-serif: "Poppins", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  //   --bs-body-font-weight: 200;
  --bs-body-line-height: 1.45;
  //   --bs-body-color: #1f1f1f;
  --bs-body-bg: #5ae5df;
}

// [dir] .navbar-light {
//   background: "#5ae5df";
// }

$primary: #ef7966 !default;
$secondary: #303179 !default;

//
